import React from "react"
import { Router } from "@reach/router"
import Presentations from "../components/Dashboard/Presentations"
import Tasks from "../components/Dashboard/Tasks"
import Login from "../components/Dashboard/Login"
import PrivateRoute from "../components/Dashboard/PrivateRoute"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"
import ScrollToTop from "../components/ScrollToTop/ScrollToTop"
import "../styles/bootstrap.scss"
import "../styles/dark.scss"

const Dashboard = () => (
  <div className="container-fluid p-0">
    <Navbar />
    <Router>
      <PrivateRoute path="/dashboard/tasks/" component={Tasks} />
      <PrivateRoute
        path="/dashboard/presentations/"
        component={Presentations}
      />
      <Login path="/dashboard/login/" />
    </Router>
    <ScrollToTop />
    <Footer />
  </div>
)

export default Dashboard
