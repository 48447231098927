import React from "react"
import { navigate } from "@reach/router"
import { FaArrowRight } from "react-icons/fa"

const Form = ({ handleSubmit, handleUpdate }) => (
  <section className="login-section">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-md-10 col-sm-12">
          <div
            className="card shadow border-primary"
            style={{
              backgroundColor:
                "#" + Math.random().toString(16).slice(-6) + "10",
            }}
          >
            <h2
              className="text-center my-4"
              style={{ fontSize: "32px", fontWeight: "900" }}
            >
              INKredibleDoc
            </h2>
            <form
              className="text-center"
              method="post"
              onSubmit={event => {
                handleSubmit(event)
                navigate(`/dashboard/presentations/`)
              }}
            >
              <div className="form-row py-2">
                <div className="col-md-12 form-group py-2">
                  <label className="text-uppercase text-primary">
                    Username
                    <input
                      className="form-control"
                      type="text"
                      name="username"
                      onChange={handleUpdate}
                    />
                  </label>
                </div>
                <div className="col-md-12 form-group py-2">
                  <label className="text-uppercase text-primary">
                    Password
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={handleUpdate}
                    />
                  </label>
                </div>
              </div>
              <div
                className="btn-group btn-group-lg text-decoration-none my-4"
                role="group"
                aria-label="Send"
              >
                <button className="btn btn-success btn-lg shadow" type="submit">
                  <span className="mx-4">Login</span>
                </button>
                <button className="btn btn-light btn-lg shadow">
                  <FaArrowRight />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Form
