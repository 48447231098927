import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyAS-BmLAlChlvnScvJdgUtHB3uTHkm-GkA",
  authDomain: "inkredibledoc-75f6b.firebaseapp.com",
  projectId: "inkredibledoc-75f6b",
  storageBucket: "inkredibledoc-75f6b.appspot.com",
  messagingSenderId: "768123929226",
  appId: "1:768123929226:web:aa94ec0dca54e49ebea373",
  measurementId: "G-WSN5X251XD",
}

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export { db }
